/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import { STEP_STATUS } from '../../welcomeBack.types';
import React, { Fragment } from 'react';
import { WelcomeBackStepHeader } from '../StepHeader';
import { WelcomeBackStepDetails } from '../StepDetails';
import { WelcomeBackSubHeading } from '../../EVApplication';
import { Box, Typography } from '@material-ui/core';
import { PageTextContentType } from '../../../../../hooks/useContentMgmt';
import { useIsMobile } from '../../../../../util/style-utils';
import PGEPlusButtons from '../../../../pge-plus-common/PGEPlusButton';
import {
  convertTimeToAPStyle,
  getTimeRangeString,
} from '../../../../../util/date-utils';
import { Maybe } from '../../../../../__generated__/pgeplus-types';

type Props = {
  status: STEP_STATUS;
  content: PageTextContentType;
  handleClickGoToQuote: () => void;
  handleViewAcceptedQuote?: () => void;
  isInstallerToCollectPayment: boolean | undefined;
  installationDateTime?:
    | Maybe<{
        start: string;
        end: string;
      }>
    | undefined;
};

const QuoteInformation = ({
  status,
  content,
  handleClickGoToQuote,
  handleViewAcceptedQuote,
  isInstallerToCollectPayment,
  installationDateTime,
}: Props) => {
  const isMobile = useIsMobile();

  return (
    <Fragment>
      <WelcomeBackStepHeader
        title={
          status !== STEP_STATUS.COMPLETED
            ? content.get('REVIEW_AND_ACCEPT_QUOTE')!
            : content.get('VIEW_YOUR_ACCEPTED_QUOTE')!
        }
        isLink={status === STEP_STATUS.COMPLETED}
        onClick={
          status === STEP_STATUS.COMPLETED ? handleViewAcceptedQuote : undefined
        }
      />

      {status === STEP_STATUS.COMPLETED && isInstallerToCollectPayment && (
        <WelcomeBackStepDetails>
          <Box marginBottom={isMobile ? '1.5rem' : '0rem'}>
            <Typography>
              {content.get('REUSABLE_INSTALLATION_DATE_AND_TIME')}
            </Typography>
            <Typography css={{ fontWeight: 'bold' }}>
              {installationDateTime &&
                getTimeRangeString(installationDateTime)?.date}
            </Typography>
            <Typography css={{ fontWeight: 'bold' }}>
              {installationDateTime &&
                convertTimeToAPStyle(
                  getTimeRangeString(installationDateTime)?.timeRange,
                )}
            </Typography>
          </Box>
        </WelcomeBackStepDetails>
      )}
      {status === STEP_STATUS.IN_PROGRESS && (
        <WelcomeBackStepDetails>
          {/* {content.get('PLEASE_REVIEW_AND_ACCEPT_QUOTE')} */}
          <WelcomeBackSubHeading
            subHeading={content.get('REVIEW_AND_ACCEPT_OR_DECLINE_QUOTE')}
          />
          <Box
            display={'flex'}
            justifyContent={isMobile ? 'center' : 'space-between'}
            marginTop={'20px'}
            alignItems={isMobile ? 'normal' : 'center'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <PGEPlusButtons onClick={handleClickGoToQuote}>
              {content.get('INSTALLATION_CUSTOMER_WELCOME_BACK_STEP_3_BUTTON')}
            </PGEPlusButtons>
          </Box>
        </WelcomeBackStepDetails>
      )}
    </Fragment>
  );
};

export default QuoteInformation;
