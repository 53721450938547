import React, { Fragment, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Link,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';
import AccountDropdown from '../account-dropdown';
import Backdrop from '../backdrop';
import colors from '../../themes/main-colors';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import useAuthQuery from '../../hooks/useAuthQuery';
import useGetAccountType from '../../hooks/useGetAccountType';
import { useIsMobile } from '../../util/style-utils';
import ReceivedSolar from '../../static/icon-paper-sheets.svg';
import ReviewSolar from '../../static/icon-clipboard.svg';
import ThumbUpSolar from '../../static/icon-thumbs-up.svg';
import MeterExchangeSolar from '../../static/icon-sun-plug.svg';
import SolarOnSolar from '../../static/icon-solar-panel.svg';
import { getRooftopSolarQuery } from './rooftopSolar.query';
import { toDateString } from '../../util/format';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.spacing(4.5),
      lineHeight: theme.typography.pxToRem(28),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      color: colors.lightCyan,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        marginBottom: '16px',
      },
    },
    accountBox: {
      margin: '16px 0px 32px',
      [theme.breakpoints.down('sm')]: {
        margin: '16px 0px 20px',
      },
    },
    iconSelected: {
      height: '85px',
      width: '85px',
    },
    icon: {
      height: '60px',
      width: '60px',
    },
  }),
);

type Props = {
  path?: string;
};

export default function RooftopSolar(_: Props) {
  const { accountParams } = useSelectedAccountParams();
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { accountTypeData } = useGetAccountType({});
  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);
  // const [roofTopStatus, setIsStatusLoadisasng] = useState<any>('MTREXHGICREQ');
  const { data: solarData, loading: isLoading } = useAuthQuery(
    getRooftopSolarQuery,
    {
      variables: {
        params: {
          accountNumberList: [accountParams],
        },
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function,no-empty-function
      onError: () => {},
      skip: !accountParams,
    },
  );
  const roofTopStatus =
    solarData?.getAccountDetails?.[0]?.premiseInfo?.[0]?.rooftopSolar
      ?.applicationDetails?.[0]?.status;
  const roofTopStatusDate = toDateString(
    solarData?.getAccountDetails?.[0]?.premiseInfo?.[0]?.rooftopSolar
      ?.applicationDetails?.[0]?.statusDate,
    'MM/dd/yyyy',
  );
  const roofTopSubmitDate = toDateString(
    solarData?.getAccountDetails?.[0]?.premiseInfo?.[0]?.rooftopSolar
      ?.applicationDetails?.[0]?.submitDate,
    'yyyy-MM-dd',
  );
  const roofTopAddress =
    solarData?.getAccountDetails?.[0]?.premiseInfo?.[0]?.addressLine1;
  const roofTopCity = solarData?.getAccountDetails?.[0]?.premiseInfo?.[0]?.city;
  const userAddress = `${roofTopAddress}${' '}${roofTopCity}`;

  const receivedStatus =
    roofTopStatus === 'APLYAPPRCV' ||
    roofTopStatus === 'APLYPDFEE' ||
    roofTopStatus === 'ACTIVE';
  const reviewStatus =
    roofTopStatus === 'RVWAPPRVW' ||
    roofTopStatus === 'RVWRESUBL3' ||
    roofTopStatus === 'RVWPRECOR' ||
    roofTopStatus === 'RVWIMPSTDY' ||
    roofTopStatus === 'RVWAPPOPT' ||
    roofTopStatus === 'SCHAPPOPT' ||
    roofTopStatus === 'APPOPTPND' ||
    roofTopStatus === 'SUPPLRVW' ||
    roofTopStatus === 'RVWFACSTDY' ||
    roofTopStatus === 'RVWAPPCOR';
  const approvedStatus =
    roofTopStatus === 'GDTOGOAPR' ||
    roofTopStatus === 'GDTOGOAPRMOD' ||
    roofTopStatus === 'GDTOGOMODINP';
  const meterExchangeStatus =
    roofTopStatus === 'MTREXHGPND' ||
    roofTopStatus === 'MTREXHGCORVW' ||
    roofTopStatus === 'MTREXHGCOREQ' ||
    roofTopStatus === 'MTREXHGICREQ';
  const solarOnStatus = roofTopStatus === 'SLRONPERM';
  const cancelledStatus =
    roofTopStatus === 'CANCELLED' || roofTopStatus === 'REMOVED';

  if (isStatusLoading || isLoading || accountTypeData === undefined) {
    return <Backdrop forceOpen message={t('STILL_LOADING')} />;
  }
  const helpDeskNumber = t('ROOFTOP_PHONE_NUMBER');

  return (
    <Fragment>
      {isLoading || isStatusLoading ? (
        <Backdrop forceOpen message={t('STILL_LOADING')} />
      ) : (
        <>
          <Typography className={classes.heading}>
            {t('ROOFTOP_TITLE')}
          </Typography>
          <Box className={classes.accountBox}>
            <AccountDropdown />
          </Box>

          <Paper style={{ boxShadow: 'none' }}>
            <Box style={{ padding: '20px' }}>
              <Box style={{ display: 'flex' }}>
                <Typography
                  style={{
                    margin: '16px 5px 0px 0px',
                    fontSize: '16px',
                    fontWeight: 700,
                  }}
                >
                  {t('ROOFTOP_INSTALLATION_ADDRESS')}
                </Typography>
                <Typography
                  style={{
                    margin: '16px 0px',
                    fontSize: '16px',
                    fontWeight: 700,
                  }}
                >
                  {roofTopAddress} {roofTopCity}
                </Typography>
              </Box>

              <Typography style={{ marginBottom: '20px', fontSize: '14px' }}>
                {t('ROOFTOP_HEADER_TEXT')}{' '}
                <a
                  href={'tel:' + helpDeskNumber}
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {t('ROOFTOP_PHONE_NUMBER')}
                </a>
              </Typography>

              <Paper
                style={{
                  backgroundColor: colors.white,
                  boxShadow: 'none',
                }}
              >
                {cancelledStatus ? (
                  <Grid
                    style={{
                      display: 'flex',
                      gridGap: 20,
                      marginBottom: '20px',
                      padding: '20px',
                      backgroundColor: colors.riverBlue,
                    }}
                  >
                    <Grid
                      style={{
                        margin: isMobile ? '0px' : '0px 10px',
                        justifySelf: 'center',
                        alignSelf: 'center',
                      }}
                    >
                      <Box
                        style={{
                          width: '80px',
                          height: '80px',
                          backgroundColor: '#00AED0',
                          borderRadius: '40px',
                        }}
                      >
                        <ReceivedSolar
                          style={{
                            width: '38px',
                            height: '47px',
                            margin: '14px 20px',
                            fill: '#3D5265',
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          margin: isMobile ? '0px' : '0px 30px 0px 0px',
                          fontWeight: 700,
                          fontSize: '24px',
                          fontFamily: 'Forma-DJR-Display',
                          color: '#00AED0',
                        }}
                      >
                        {t('ROOFTOP_ APPLICATION_CANCELED')}
                      </Typography>
                      <Typography
                        style={{
                          margin: isMobile ? '0px' : '0px 30px 0px 0px',
                          fontFamily: 'Untitled-Sans',
                          fontSize: '14px',
                        }}
                      >
                        {t('ROOFTOP_CANCELLED_CONTENT')}
                        <a
                          href={'tel:' + helpDeskNumber}
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          {t('ROOFTOP_PHONE_NUMBER')}{' '}
                        </a>
                        {t('ROOFTOP_ APPLICATION_CANCELED_CONTENT')}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {/* Recieved UI */}
                    <Grid
                      style={{
                        display: 'flex',
                        gridGap: 20,
                        marginBottom: '20px',
                        padding: receivedStatus ? '20px' : '0px',
                        backgroundColor: receivedStatus
                          ? colors.riverBlue
                          : colors.white,
                      }}
                    >
                      <Grid
                        style={{
                          margin: isMobile
                            ? '0px'
                            : receivedStatus
                            ? '0px 20px'
                            : '0px 30px',
                          justifySelf: 'center',
                          alignSelf: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: receivedStatus ? '80px' : '60px',
                            height: receivedStatus ? '80px' : '60px',
                            backgroundColor: receivedStatus
                              ? '#00AED0'
                              : '#FFFFFF',
                            borderRadius: receivedStatus ? '40px' : '30px',
                          }}
                        >
                          <ReceivedSolar
                            style={{
                              width: receivedStatus ? '38px' : '27px',
                              height: receivedStatus ? '47px' : '31px',
                              margin: receivedStatus
                                ? '14px 20px'
                                : '14px 16px',
                              fill: receivedStatus
                                ? '#FEFDF2'
                                : reviewStatus ||
                                  approvedStatus ||
                                  meterExchangeStatus ||
                                  solarOnStatus
                                ? '#3D5265'
                                : '#A6A6A6',
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            margin: isMobile
                              ? '0px'
                              : receivedStatus
                              ? '0px 30px 0px 0px'
                              : '0px 20px',
                            fontWeight: receivedStatus ? 700 : 600,
                            fontSize: receivedStatus ? '24px' : '16px',
                            fontFamily: receivedStatus
                              ? 'Forma-DJR-Display'
                              : 'Untitled-Sans',
                            color: receivedStatus ? '#00AED0' : colors.noirBlur,
                          }}
                        >
                          {receivedStatus
                            ? t('ROOFTOP_ APPLICATION_SUBMISSION_IN_PROGRESS')
                            : reviewStatus ||
                              approvedStatus ||
                              meterExchangeStatus ||
                              solarOnStatus
                            ? t('ROOFTOP_ APPLICATION_SUBMISSION_COMPLETED')
                            : t('ROOFTOP_ APPLICATION_SUBMISSION')}
                        </Typography>

                        {receivedStatus ? (
                          <Typography
                            style={{
                              margin: isMobile
                                ? '0px'
                                : receivedStatus
                                ? '0px 30px 0px 0px'
                                : '0px 20px',
                              fontFamily: 'Untitled-Sans',
                              fontSize: '14px',
                            }}
                          >
                            {richT('ROOFTOP_RECEIVED_INPROGRESS_CONTENT', {
                              ADDRESS: userAddress,
                            })}
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              margin: isMobile
                                ? '0px'
                                : receivedStatus
                                ? '0px 30px 0px 0px'
                                : '-12px 20px 0px 20px',
                              fontFamily: 'Untitled-Sans',
                              fontSize: '14px',
                            }}
                          >
                            {richT('ROOFTOP_RECEIVED_CONTENT', {
                              SUBMIT_DATE: roofTopSubmitDate,
                            })}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    {/* Review UI */}
                    <Grid
                      style={{
                        display: 'flex',
                        gridGap: 20,
                        marginBottom: '20px',
                        padding: reviewStatus ? '20px' : '0px',
                        backgroundColor: reviewStatus
                          ? colors.riverBlue
                          : colors.white,
                      }}
                    >
                      <Grid
                        style={{
                          margin: isMobile
                            ? '0px'
                            : reviewStatus
                            ? '0px 20px'
                            : '0px 30px',
                          justifySelf: 'center',
                          alignSelf: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: reviewStatus ? '80px' : '60px',
                            height: reviewStatus ? '80px' : '60px',
                            backgroundColor: reviewStatus
                              ? '#00AED0'
                              : '#FFFFFF',
                            borderRadius: reviewStatus ? '40px' : '30px',
                          }}
                        >
                          <ReviewSolar
                            style={{
                              width: reviewStatus ? '38px' : '27px',
                              height: reviewStatus ? '47px' : '31px',
                              margin: reviewStatus ? '14px 20px' : '14px 16px',
                              fill: reviewStatus
                                ? '#FEFDF2'
                                : approvedStatus ||
                                  meterExchangeStatus ||
                                  solarOnStatus
                                ? '#3D5265'
                                : '#A6A6A6',
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            margin: isMobile
                              ? '0px'
                              : reviewStatus
                              ? '0px 30px 0px 0px'
                              : '0px 20px',
                            fontWeight: reviewStatus ? 700 : 600,
                            fontSize: reviewStatus ? '24px' : '16px',
                            fontFamily: reviewStatus
                              ? 'Forma-DJR-Display'
                              : 'Untitled-Sans',
                            color: reviewStatus ? '#00AED0' : colors.noirBlur,
                          }}
                        >
                          {reviewStatus
                            ? t('ROOFTOP_ REVIEW_TITLE_IN_PROGRESS')
                            : approvedStatus ||
                              meterExchangeStatus ||
                              solarOnStatus
                            ? t('ROOFTOP_ REVIEW_TITLE_COMPLETED')
                            : t('ROOFTOP_ REVIEW_TITLE')}
                        </Typography>
                        {reviewStatus ? (
                          <Typography
                            style={{
                              margin: isMobile
                                ? '0px'
                                : reviewStatus
                                ? '0px 30px 0px 0px'
                                : '0px 20px',
                              fontFamily: 'Untitled-Sans',
                              fontSize: '14px',
                            }}
                          >
                            {richT('ROOFTOP_REVIEW_INPROGRESS_CONTENT', {
                              ADDRESS: userAddress,
                            })}
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              margin: isMobile
                                ? '0px'
                                : reviewStatus
                                ? '0px 30px 0px 0px'
                                : '0px 20px',
                              fontFamily: 'Untitled-Sans',
                              fontSize: '14px',
                            }}
                          >
                            {t('ROOFTOP_REVIEW_CONTENT')}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    {/* Approval UI */}
                    <Grid
                      style={{
                        display: 'flex',
                        gridGap: 20,
                        marginBottom: '20px',
                        padding: approvedStatus ? '20px' : '0px',
                        backgroundColor: approvedStatus
                          ? colors.riverBlue
                          : colors.white,
                      }}
                    >
                      <Grid
                        style={{
                          margin: isMobile
                            ? '0px'
                            : approvedStatus
                            ? '0px 20px'
                            : '0px 30px',
                          justifySelf: 'center',
                          alignSelf: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: approvedStatus ? '80px' : '60px',
                            height: approvedStatus ? '80px' : '60px',
                            backgroundColor: approvedStatus
                              ? '#00AED0'
                              : '#FFFFFF',
                            borderRadius: approvedStatus ? '40px' : '30px',
                          }}
                        >
                          <ThumbUpSolar
                            style={{
                              width: approvedStatus ? '38px' : '27px',
                              height: approvedStatus ? '47px' : '31px',
                              margin: approvedStatus
                                ? '14px 20px'
                                : '14px 16px',
                              fill: approvedStatus
                                ? '#FEFDF2'
                                : meterExchangeStatus || solarOnStatus
                                ? '#3D5265'
                                : '#A6A6A6',
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            margin: isMobile
                              ? '0px'
                              : approvedStatus
                              ? '0px 30px 0px 0px'
                              : '0px 20px',
                            fontWeight: approvedStatus ? 700 : 600,
                            fontSize: approvedStatus ? '24px' : '16px',
                            fontFamily: approvedStatus
                              ? 'Forma-DJR-Display'
                              : 'Untitled-Sans',
                            color: approvedStatus ? '#00AED0' : colors.noirBlur,
                          }}
                        >
                          {approvedStatus
                            ? t('ROOFTOP_ APPROVAL_TITLE_IN_PROGRESS')
                            : meterExchangeStatus || solarOnStatus
                            ? t('ROOFTOP_ APPROVAL_TITLE_COMPLETED')
                            : t('ROOFTOP_ APPROVAL_TITLE')}
                        </Typography>

                        {approvedStatus ? (
                          <Typography
                            style={{
                              margin: isMobile
                                ? '0px'
                                : approvedStatus
                                ? '0px 30px 0px 0px'
                                : '0px 20px',
                              fontFamily: 'Untitled-Sans',
                              fontSize: '14px',
                            }}
                          >
                            {richT('ROOFTOP_APPROVAL_INPROGRESS_CONTENT', {
                              ADDRESS: userAddress,
                            })}
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              margin: isMobile
                                ? '0px'
                                : approvedStatus
                                ? '0px 30px 0px 0px'
                                : '0px 20px',
                              fontFamily: 'Untitled-Sans',
                              fontSize: '14px',
                            }}
                          >
                            {t('ROOFTOP_APPROVAL_CONTENT')}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    {/* MeterExchange UI */}
                    <Grid
                      style={{
                        display: 'flex',
                        gridGap: 20,
                        marginBottom: '20px',
                        padding: meterExchangeStatus ? '20px' : '0px',
                        backgroundColor: meterExchangeStatus
                          ? colors.riverBlue
                          : colors.white,
                      }}
                    >
                      <Grid
                        style={{
                          margin: isMobile
                            ? '0px'
                            : meterExchangeStatus
                            ? '0px 20px'
                            : '0px 30px',
                          justifySelf: 'center',
                          alignSelf: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: meterExchangeStatus ? '80px' : '60px',
                            height: meterExchangeStatus ? '80px' : '60px',
                            backgroundColor: meterExchangeStatus
                              ? '#00AED0'
                              : '#FFFFFF',
                            borderRadius: meterExchangeStatus ? '40px' : '30px',
                          }}
                        >
                          <MeterExchangeSolar
                            style={{
                              width: meterExchangeStatus ? '38px' : '27px',
                              height: meterExchangeStatus ? '47px' : '31px',
                              margin: meterExchangeStatus
                                ? '14px 20px'
                                : '14px 16px',
                              fill: meterExchangeStatus
                                ? '#FEFDF2'
                                : solarOnStatus
                                ? '#3D5265'
                                : '#A6A6A6',
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            margin: isMobile
                              ? '0px'
                              : meterExchangeStatus
                              ? '0px 30px 0px 0px'
                              : '0px 20px',
                            fontWeight: meterExchangeStatus ? 700 : 600,
                            fontSize: meterExchangeStatus ? '24px' : '16px',
                            fontFamily: meterExchangeStatus
                              ? 'Forma-DJR-Display'
                              : 'Untitled-Sans',
                            color: meterExchangeStatus
                              ? '#00AED0'
                              : colors.noirBlur,
                          }}
                        >
                          {meterExchangeStatus
                            ? t('ROOFTOP_ METER_UPDATE_TITLE_IN_PROGRESS')
                            : solarOnStatus
                            ? t('ROOFTOP_ METER_UPDATE_TITLE_COMPLETED')
                            : t('ROOFTOP_ METER_UPDATE_TITLE')}
                        </Typography>

                        {meterExchangeStatus ? (
                          <Grid
                            style={{ display: 'flex', flexDirection: 'column' }}
                            container={true}
                          >
                            <Typography
                              style={{
                                margin: isMobile
                                  ? '0px'
                                  : meterExchangeStatus
                                  ? '0px 30px 0px 0px'
                                  : '0px 20px',
                                fontFamily: 'Untitled-Sans',
                                fontSize: '14px',
                              }}
                            >
                              {richT(
                                'ROOFTOP_METER_EXCHANGE_INPROGRESS_CONTENT',
                                {
                                  ADDRESS: userAddress,
                                },
                              )}
                            </Typography>

                            <Typography
                              style={{
                                margin: isMobile
                                  ? '0px'
                                  : meterExchangeStatus
                                  ? '0px 30px 0px 0px'
                                  : '0px 20px',
                                fontFamily: 'Untitled-Sans',
                                fontSize: '14px',
                              }}
                            >
                              {t('ROOFTOP_RECEIVED_TEXT')}
                              <Link
                                href="https://www.outlook.com"
                                target="_blank"
                                css={{
                                  fontWeight: 700,
                                  fontSize: '16px',
                                  fontFamily: 'Untitled-Sans',
                                  color: colors.sparkBlue,
                                  lineHeight: '20px',
                                  textDecoration: 'underline',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {' '}
                                {t('ROOFTOP_EMAIL')}{' '}
                              </Link>
                              {t('ROOFTOP_ METER_UPDATE_CALL_US')}{' '}
                              <a
                                href={'tel:' + helpDeskNumber}
                                style={{
                                  fontWeight: 700,
                                }}
                              >
                                {t('ROOFTOP_PHONE_NUMBER')}{' '}
                              </a>
                              {t('ROOFTOP_METEREXCHANGE_INPROGRESS_TEXT')}
                            </Typography>
                            <Typography
                              style={{
                                margin: isMobile
                                  ? '0px'
                                  : meterExchangeStatus
                                  ? '20px 30px 0px 0px'
                                  : '0px 20px',
                                fontFamily: 'Untitled-Sans',
                                fontSize: '14px',
                              }}
                            >
                              {t('ROOFTOP_TEXT_METER_EXCHANGE')}
                            </Typography>
                          </Grid>
                        ) : (
                          <Typography
                            style={{
                              margin: isMobile
                                ? '0px'
                                : meterExchangeStatus
                                ? '0px 30px 0px 0px'
                                : '0px 20px',
                              fontFamily: 'Untitled-Sans',
                              fontSize: '14px',
                            }}
                          >
                            {t('ROOFTOP_METER_EXCHANGE_CONTENT')}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    {/* Solar On UI */}
                    <Grid
                      style={{
                        display: 'flex',
                        gridGap: 20,
                        marginBottom: '20px',
                        padding: solarOnStatus ? '20px' : '0px',
                        backgroundColor: solarOnStatus
                          ? colors.riverBlue
                          : colors.white,
                      }}
                    >
                      <Grid
                        style={{
                          margin: isMobile
                            ? '0px'
                            : solarOnStatus
                            ? '0px 20px'
                            : '0px 30px',
                          justifySelf: 'center',
                          alignSelf: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: solarOnStatus ? '80px' : '60px',
                            height: solarOnStatus ? '80px' : '60px',
                            backgroundColor: solarOnStatus
                              ? '#00AED0'
                              : '#FFFFFF',
                            borderRadius: solarOnStatus ? '40px' : '30px',
                          }}
                        >
                          <SolarOnSolar
                            style={{
                              width: solarOnStatus ? '38px' : '27px',
                              height: solarOnStatus ? '47px' : '31px',
                              margin: solarOnStatus ? '14px 20px' : '14px 16px',
                              fill: solarOnStatus
                                ? '#FEFDF2'
                                : solarOnStatus
                                ? '#3D5265'
                                : '#A6A6A6',
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            margin: isMobile
                              ? '0px'
                              : solarOnStatus
                              ? '0px 30px 0px 0px'
                              : '0px 20px',
                            fontWeight: solarOnStatus ? 700 : 600,
                            fontSize: solarOnStatus ? '24px' : '16px',
                            fontFamily: solarOnStatus
                              ? 'Forma-DJR-Display'
                              : 'Untitled-Sans',
                            color: solarOnStatus ? '#00AED0' : colors.noirBlur,
                          }}
                        >
                          {t('ROOFTOP_ SOLAR_ON_TITLE')}
                        </Typography>

                        {solarOnStatus ? (
                          <Typography
                            style={{
                              margin: isMobile
                                ? '0px'
                                : solarOnStatus
                                ? '0px 30px 0px 0px'
                                : '0px 20px',
                              fontFamily: 'Untitled-Sans',
                              fontSize: '14px',
                            }}
                          >
                            {richT('ROOFTOP_SOLARON_INPROGRESS_CONTENT', {
                              ADDRESS: userAddress,
                            })}
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              margin: isMobile
                                ? '0px'
                                : solarOnStatus
                                ? '0px 30px 0px 0px'
                                : '0px 20px',
                              fontFamily: 'Untitled-Sans',
                              fontSize: '14px',
                            }}
                          >
                            {t('ROOFTOP_SOLARON_CONTENT')}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>{' '}
                  </>
                )}
                <Divider />
                <Typography
                  style={{
                    margin: '20px',
                    fontSize: '14px',
                    textAlign: 'center',
                  }}
                >
                  {t('ROOFTOP_APP_STATUS_CONTENT')}
                  {roofTopStatusDate}
                </Typography>
              </Paper>
            </Box>
          </Paper>
        </>
      )}
    </Fragment>
  );
}
