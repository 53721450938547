import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import Ineligible from '../../auto-pay/auto-pay-start/Ineligible';
import useStyles from '../../payment-form/PaymentForm.styles';
import { UsePaymentFormResponse } from '../PaymentForm.types';
import {
  AccountDetail,
  PaymentProfile,
  AccountCustomer,
  PaymentCategory,
  PaymentEligibility,
  Scalars,
  Maybe,
} from '../../../__generated__/pge-types';
import { OneTimePayConfigData } from '../oneTimePayment.types';
import {
  getDefaultOneTimePaymentInfoFormData,
  PaymentInfo,
} from '../oneTimePay.utils';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { usePaymentInfoFormState } from '../../../hooks/useOneTimePayment';
import PGEButton from '../../buttons';
import { Box } from '@material-ui/core';
import { isMobile } from '../../../util/style-utils';
import { PaymentProfileInfo } from '../../paymentus/types';
import TextLink from '../../text-link/TextLink';
import PaymentProfileOnetimeSplitSelector from '../../paymentus/payment-profile-onetime-split-selector/PaymentProfileOnetimeSplitSelector';
import PaymentAccordian from '../../payment-accordian/PaymentAccordian';
import PaymentLimitsFees from '../../paymentus/payment-limits-fees';
import AddPaymentMethodDropdown from '../../payment-method-type/AddPaymentMethodDropdown';
import PaymentMethodTypeIconList from '../../payment-method-type/PaymentMethodTypeIconList';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import PaymentSlider from '../payment-slider/PaymentSlider';
export interface PaymentInfoFormProps extends UsePaymentFormResponse {
  path: string;
  isMobile: boolean;
  //customer: AccountCustomer | undefined;
  //account: AccountDetail;
  onSubmit: (data: OneTimePayConfigData) => void;
  oneTimePayConfigData: OneTimePayConfigData | null;
  savedProfileList: PaymentProfile[] | undefined;
  paymentMethodCapLimit: number;
  handleEligible: (value: boolean) => void;
  //isForTPA?: boolean;
  //amountToBePaid?: string;
  //calendarEndDate?: Date;
  maxPaymentDate?: Date;
  isForReconnect?: boolean;
  fullAmount?: string;
  minimumAmount?: string;
  maximumAmount?: number;
  paymentInfo: PaymentInfo;
  disableAmountField?: boolean;
  disableDateField?: boolean;
  backButtonText?: string;
  backButtonRoute?: string;
  nextButtonText?: string;
  disableNextButton?: boolean;
  nextButtonRoute?: string;
  backButtonPosition?: 'left' | 'right';
  cardFooterContent?: React.ReactNode;
  enableDonation?: boolean;
  defaultAmount?: number;
  disableSplitPay?: boolean;
}

const PaymentInfoForm: FunctionComponent<PaymentInfoFormProps> = props => {
  const { t, richT } = useTranslation();
  const {
    backButtonText = t('BACK'),
    backButtonRoute,
    nextButtonText = t('NEXT'),
    nextButtonRoute,
    backButtonPosition = 'left',
    disableNextButton = false,
    disableSplitPay = false,
  } = props;
  const classes = useStyles();
  const [nextButton, setNextButton] = useState(false);
  const [eligibility, setEligibility] = useState(true);
  const [oneTimePayConfig, setOneTimePayConfig] = useState<
    OneTimePayConfigData
  >(
    props.oneTimePayConfigData ||
      getDefaultOneTimePaymentInfoFormData(
        props?.paymentInfo,
        props.savedProfileList,
        props.maxPaymentDate,
        props.minimumAmount,
        props.maximumAmount,
        props?.defaultAmount,
      ),
  );

  const [reconnectPayAmount, setReconnectPayAmount] = useState<string>(
    oneTimePayConfig?.paymentAmount,
  );

  useEffect(() => {
    if (props.isForReconnect) {
      paymentInfoFormState.values.paymentAmount = String(reconnectPayAmount);

      setNextButton(
        Array.isArray(
          oneTimePayConfig.paymentSelector.selectedPaymentProfileList,
        ) &&
          oneTimePayConfig.paymentSelector.selectedPaymentProfileList.length &&
          Number(paymentInfoFormState.values.paymentAmount) >= 1 &&
          Number(paymentInfoFormState.values.paymentAmount) <=
            Number(props.fullAmount) &&
          Number(paymentInfoFormState.values.paymentAmount) >=
            Number(props.minimumAmount) &&
          !paymentInfoFormState.errors?.paymentAmount &&
          !paymentInfoFormState.errors?.paymentDate
          ? false
          : true,
      );
      setOneTimePayConfig(config => {
        config.paymentAmount = String(reconnectPayAmount);
        return { ...config };
      });
    }
  }, [reconnectPayAmount]);

  const paymentInfoFormState = usePaymentInfoFormState(oneTimePayConfig);

  const { wrapWithLoader } = useWrapWithLoader();

  const isCashOnly = Boolean(
    props?.paymentInfo?.paymentEligibility?.isCashOnly,
  );
  const notificationContext = useContext(NotificationsContext);
  const handleNext = wrapWithLoader(
    paymentInfoFormState.submit(async () => {
      try {
        if (eligibility) {
          oneTimePayConfig.paymentAmount =
            paymentInfoFormState.values.paymentAmount;
          oneTimePayConfig.paymentDate =
            paymentInfoFormState.values.paymentDate;
          props.onSubmit(oneTimePayConfig);

          nextButtonRoute && navigate(nextButtonRoute);
        } else {
          props.handleEligible(true);
          window.scrollTo(0, 0);
        }
      } catch (_) {
        // Do nothing, this means, there was a form error
      }
    }),
  );

  const isNonBillable = Boolean(
    props?.paymentInfo?.paymentEligibility?.isNonBillableNoBalance,
  );

  const splitCondition = oneTimePayConfig.splitAmounts.filter(
    item => item.error === true,
  );
  useEffect(() => {
    if (
      oneTimePayConfig.paymentSelector.selectedPaymentProfileList.length ===
        props.paymentMethodCapLimit &&
      oneTimePayConfig.splitPayStatus
    ) {
      setNextButton(splitCondition.length ? true : false);
    }
  }, [splitCondition.length >= 1]);

  useEffect(() => {
    let nextButtonDisableStatus = true;
    if (
      oneTimePayConfig.splitPayStatus &&
      !Boolean(oneTimePayConfig.selectedAccordian)
    ) {
      if (
        oneTimePayConfig.paymentSelector.selectedPaymentProfileList.length ===
          props.paymentMethodCapLimit &&
        Number(paymentInfoFormState.values.paymentAmount) >= 2
      ) {
        nextButtonDisableStatus = oneTimePayConfig.paymentSelector.selectedPaymentProfileList.some(
          profile => profile.error === true,
        )
          ? true
          : false;
      }
    } else {
      if (
        oneTimePayConfig.paymentSelector.selectedPaymentProfileList.length &&
        Number(paymentInfoFormState.values.paymentAmount) >= 1
      ) {
        nextButtonDisableStatus = false;
      }
    }
    setNextButton(
      !paymentInfoFormState.errors?.paymentAmount &&
        !paymentInfoFormState.errors?.paymentDate
        ? nextButtonDisableStatus
        : true,
    );
  }, [oneTimePayConfig.splitPayStatus, paymentInfoFormState.errors]);

  useEffect(() => {
    const selectedProfilesArray =
      oneTimePayConfig.paymentSelector.selectedPaymentProfileList;
    let nextButtonDisableStatus = true;
    if (
      Array.isArray(selectedProfilesArray) &&
      selectedProfilesArray.length &&
      !Boolean(oneTimePayConfig.selectedAccordian)
    ) {
      if (oneTimePayConfig.splitPayStatus) {
        if (
          selectedProfilesArray.length === props.paymentMethodCapLimit &&
          Number(paymentInfoFormState.values.paymentAmount) >= 2
        ) {
          nextButtonDisableStatus = selectedProfilesArray.some(
            profile => profile.error === true,
          )
            ? true
            : false;
        }
      } else {
        nextButtonDisableStatus = selectedProfilesArray.some(
          p => p.profile.token === '',
        )
          ? true
          : selectedProfilesArray.some(profile => profile.error === true)
          ? true
          : false;
        if (props.isForReconnect && paymentInfoFormState.values.paymentAmount) {
          nextButtonDisableStatus =
            Number(paymentInfoFormState.values.paymentAmount) >= 1 &&
            Number(paymentInfoFormState.values.paymentAmount) <=
              Number(props.fullAmount) &&
            Number(paymentInfoFormState.values.paymentAmount) >=
              Number(props.minimumAmount)
              ? false
              : true;
        }
      }
      setEligibility(
        selectedProfilesArray.some(
          p => p.profile.type === 'CHQ' || p.profile.type === 'SAV',
        ) && isCashOnly
          ? false
          : true,
      );
    } else {
      if (
        Array.isArray(selectedProfilesArray) &&
        selectedProfilesArray.length === 0
      ) {
        nextButtonDisableStatus = true;
      } else {
        nextButtonDisableStatus =
          Number(paymentInfoFormState.values.paymentAmount) >= 1 ? false : true;
      }
    }
    setNextButton(
      !paymentInfoFormState.errors?.paymentAmount &&
        !paymentInfoFormState.errors?.paymentDate
        ? nextButtonDisableStatus
        : true,
    );
  }, [oneTimePayConfig.paymentSelector.selectedPaymentProfileList]);

  const deletePaymentProfileFromList = (deletedProfile: PaymentProfileInfo) => {
    window.scrollTo(0, 0);
    setOneTimePayConfig(config => {
      if (Array.isArray(config.paymentSelector.selectedPaymentProfileList)) {
        config.paymentSelector.selectedPaymentProfileList.some(
          profile => profile.profile.token === deletedProfile.profile.token,
        )
          ? (config.paymentSelector.selectedPaymentProfileList = config.paymentSelector.selectedPaymentProfileList.filter(
              ({ profile }) => profile.token !== deletedProfile.profile.token,
            ))
          : null;
      }
      if (Array.isArray(config.paymentSelector.savedProfileList)) {
        config.paymentSelector.savedProfileList = config.paymentSelector.savedProfileList.filter(
          p => p.token !== deletedProfile.profile.token,
        );
      }
      config.initialLoading = false;
      return { ...config };
    });
  };

  const updateSavedPaymentProfiles = (profile: PaymentProfileInfo) => {
    window.scrollTo(0, 0);
    if (profile) {
      notificationContext.setState({
        isOpen: true,
        severity: 'success',
        variant: 'filled',
        message: t('PAYMENT_PROFILE_ADD_SUCCESS_MESSAGE'),
      });
    }
    setOneTimePayConfig(config => {
      if (Array.isArray(config.paymentSelector.savedProfileList)) {
        config.paymentSelector.savedProfileList.splice(
          config.paymentSelector.savedProfileList.length - 1,
          0,
          profile.profile,
        );
      }
      config.initialLoading = false;
      return { ...config };
    });
  };

  if (isNonBillable) {
    return (
      <Ineligible title={t('ONLINE_PAYMENT_UNAVAILABLE')}>
        {richT('ONETIME_PAY_INELIGIBLE_NON_BILLABLE')}
      </Ineligible>
    );
  }

  const convertAmountInTwoParts = (number: string) => {
    const numbersArray = number.split('.');
    const halfAmount = String((Number(numbersArray[0]) / 2).toFixed(2));
    const secondHalf = String(
      (Number(halfAmount) + Number('0.' + numbersArray[1])).toFixed(2),
    );
    return [secondHalf, halfAmount];
  };

  const updateSplitPayAmount = (index: string, value: string) => {
    let isError: boolean = false,
      errorText: string = '';
    if (Number(value) < 1) {
      isError = true;
      errorText = t('SPLITPAY_LESSTHAN_AMOUNT_TEXT');
    }
    if (Number(value) > Number(paymentInfoFormState.values.paymentAmount)) {
      isError = true;
      errorText = t('SPLITPAY_PAYMENT_AMOUNT_IS_HIGHER_THAN_DUE');
    }
    setOneTimePayConfig(config => {
      config.splitAmounts.map((item, i) => {
        if (i === Number(index)) {
          item.value = value;
          item.error = isError;
          item.errorText = errorText;
        } else {
          if (!isError) {
            if (
              Number(
                String(
                  (
                    Number(paymentInfoFormState.values.paymentAmount) -
                    Number(value)
                  ).toFixed(2),
                ),
              ) < 1
            ) {
              item.errorText = t('SPLITPAY_LESSTHAN_AMOUNT_TEXT');
              item.error = true;
              item.value = String(
                (
                  Number(paymentInfoFormState.values.paymentAmount) -
                  Number(value)
                ).toFixed(2),
              );
            } else {
              item.errorText = '';
              item.error = false;
              item.value = String(
                (
                  Number(paymentInfoFormState.values.paymentAmount) -
                  Number(value)
                ).toFixed(2),
              );
            }
          }
        }
      });
      if (
        config.paymentSelector.selectedPaymentProfileList.length ===
        props.paymentMethodCapLimit
      ) {
        config.paymentSelector.selectedPaymentProfileList
          .filter(({ isPrimary }) => isPrimary === true)
          .map(profile => {
            profile.value = config.splitAmounts[0].value!;
            profile.error = config.splitAmounts[0].error!;
            profile.errorText = config.splitAmounts[0].errorText!;
          });
        config.paymentSelector.selectedPaymentProfileList
          .filter(({ isPrimary }) => isPrimary === false)
          .map(profile => {
            profile.value = config.splitAmounts[1].value!;
            profile.error = config.splitAmounts[1].error!;
            profile.errorText = config.splitAmounts[1].errorText!;
          });
      }
      return { ...config };
    });
  };
  const updateSplitPayValue = (splitStatus: boolean) => {
    setOneTimePayConfig(config => {
      config.splitPayStatus = splitStatus;
      if (splitStatus) {
        props.isForReconnect ? (config.splitAmounts = []) : null;
        const amountsArray = convertAmountInTwoParts(
          String(Number(paymentInfoFormState.values.paymentAmount).toFixed(2)),
        );
        amountsArray.map((amount, i) => {
          config.splitAmounts.push({
            value:
              Number(paymentInfoFormState.values.paymentAmount) > 0
                ? amount
                : '0.00',
            error:
              Number(paymentInfoFormState.values.paymentAmount) < 2
                ? true
                : false,
            errorText:
              Number(paymentInfoFormState.values.paymentAmount) < 2
                ? t('SPLITPAY_LESSTHAN_AMOUNT_TEXT')
                : '',
          });
        });
      } else {
        config.splitAmounts = [];
        const removeSecondaryFromArray = config.paymentSelector.selectedPaymentProfileList.filter(
          profile => profile.isPrimary === true,
        );
        if (removeSecondaryFromArray.length) {
          removeSecondaryFromArray.map(p => {
            p.value = paymentInfoFormState.values.paymentAmount;
            p.error = false;
            p.errorText = '';
            p.isPrimary = true;
          });
        }
        config.paymentSelector.selectedPaymentProfileList = removeSecondaryFromArray;
      }
      return { ...config };
    });
  };
  const onAccordianSelection = (index: number) => {
    // window.scrollTo(0, 0);
    if (oneTimePayConfig.selectedAccordian !== index) {
      setOneTimePayConfig(config => {
        config.splitPayStatus = false;
        config.splitAmounts = [];
        if (Boolean(index)) {
          //second accordian selected
          config.paymentSelector.selectedPaymentProfileList = [];
        } else {
          //first accordian selected
          if (config.paymentSelector.savedProfileList!.length) {
            config.paymentSelector.selectedPaymentProfileList = [
              {
                profile: oneTimePayConfig.paymentSelector.savedProfileList!.some(
                  p => p.default === true,
                )
                  ? oneTimePayConfig.paymentSelector.savedProfileList!.filter(
                      p => p.default === true,
                    )[0]
                  : oneTimePayConfig.paymentSelector.savedProfileList![0],
                error: false,
                errorText: '',
                isPrimary: true,
                value: paymentInfoFormState.values.paymentAmount,
              },
            ];
          } else {
            config.paymentSelector.selectedPaymentProfileList = [];
          }
        }
        config.selectedAccordian = index;
        return { ...config };
      });
    }
  };
  const addOrUpdateProps = {
    onNew: (profile: PaymentProfileInfo) => {
      onTempProfileSuccess(profile);
    },
    allowedCategories: [
      PaymentCategory.Dd,
      PaymentCategory.Cc,
      PaymentCategory.Dc,
      PaymentCategory.PaypalAccount,
      PaymentCategory.AmazonPay,
    ],
    isPrimaryPM: false,
    customLabel: t('ENTER_A_PAYMENT_METHOD'),
  };
  //After Add payment method component integrate - TODO
  const onTempProfileSuccess = (profile: PaymentProfileInfo) => {
    if (
      !paymentInfoFormState.errors.paymentAmount &&
      !paymentInfoFormState.errors.paymentDate
    ) {
      oneTimePayConfig.paymentSelector.selectedPaymentProfileList = [];
      oneTimePayConfig.paymentSelector.selectedPaymentProfileList = [
        {
          profile: profile.profile,
          error: false,
          errorText: '',
          value: paymentInfoFormState.values.paymentAmount,
        },
      ];
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleNext();
  };

  return (
    <form noValidate onSubmit={handleNext}>
      <Grid container spacing={3} className={classes.oneTimeContainer}>
        <Grid item xs={12} style={{ width: '100%' }}>
          <PaymentSlider
            paymentInfoFormState={paymentInfoFormState}
            oneTimePayConfig={oneTimePayConfig}
            setOneTimePayConfig={setOneTimePayConfig}
            setReconnectPayAmount={setReconnectPayAmount}
            reconnectPayAmount={reconnectPayAmount}
            isForReconnect={props?.isForReconnect}
            minimumAmount={props?.minimumAmount}
            disableDateField={props?.disableDateField}
            fullAmount={props?.fullAmount}
            paymentInfo={props?.paymentInfo}
            disableAmountField={props?.disableAmountField}
            enableDonation={
              props?.enableDonation &&
              !oneTimePayConfig.splitPayStatus &&
              !(props?.paymentInfo?.pastDueAmount! > 0) &&
              !props?.isForReconnect
            }
            cardFooterContent={props?.cardFooterContent}
          />

          <Grid
            item
            xs={12}
            className={classes.paymentWallet}
            id="walletSection"
          >
            <Grid
              container
              direction="row"
              justify={'space-between'}
              className={classes.margin2}
            >
              <Grid item>
                <Box marginBottom={1}>
                  <Typography variant="h2">
                    {' '}
                    {t('HOW_DO_YOU_WANT_TO_PAY')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item justify={'flex-end'}>
                <PaymentLimitsFees tabIndex={-1} />
              </Grid>
            </Grid>
            <PaymentAccordian
              onIndexChange={index => {
                onAccordianSelection(index);
              }}
              accordionItems={[
                {
                  selectedTab:
                    oneTimePayConfig.selectedAccordian === 0 ? true : false,
                  tabTitle: t('PAYMENT_METHOD_SELECTOR_TITLE'),
                  tabSubTitle: t('PAYMENT_METHOD_SELECTOR_SUBTITLE'),
                  children: (
                    <PaymentProfileOnetimeSplitSelector
                      paymentAmountByUser={
                        paymentInfoFormState.values.paymentAmount
                      }
                      paymentMethodCapLimit={props.paymentMethodCapLimit}
                      disableSplitPay={disableSplitPay}
                      OneTimePayConfig={oneTimePayConfig}
                      selectedPaymentList={
                        oneTimePayConfig.paymentSelector
                          .selectedPaymentProfileList
                      }
                      splitPayLabelClick={splitStatus => {
                        updateSplitPayValue(splitStatus);
                      }}
                      onAmountChange={(index, value) => {
                        updateSplitPayAmount(index, value);
                      }}
                      PaymentMethodChange={profiles => {
                        setOneTimePayConfig(config => ({
                          ...config,
                          paymentSelector: {
                            ...config.paymentSelector,
                            selectedPaymentProfileList: profiles,
                          },
                        }));
                      }}
                      AddNewPaymentProfile={profile => {
                        updateSavedPaymentProfiles(profile);
                      }}
                      DeletePaymentProfile={profile => {
                        deletePaymentProfileFromList(profile);
                      }}
                    />
                  ),
                },
                {
                  selectedTab:
                    oneTimePayConfig.selectedAccordian === 1 ? true : false,
                  tabTitle: t('PAYMENT_WITHOUT_SAVING_TITLE'),
                  tabSubTitle: t('PAYMENT_WITHOUT_SAVING_SUBTITLE'),
                  children: (
                    <>
                      <Grid container item spacing={1} direction="column">
                        <Grid item container>
                          <AddPaymentMethodDropdown {...addOrUpdateProps} />
                        </Grid>
                        <Grid item>
                          <Grid container direction="row">
                            <PaymentMethodTypeIconList
                              labelComponent={
                                <Typography> + {t('WE_ACCEPT')}</Typography>
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ),
                },
              ]}
            ></PaymentAccordian>
          </Grid>
        </Grid>
        <Grid
          container
          id="navigation-panel"
          className={classes.actionArea}
          direction={isMobile() ? 'column-reverse' : 'row'}
          justify={
            backButtonPosition === 'right' ? 'flex-end' : 'space-between'
          }
          style={{ paddingRight: '1em' }}
        >
          <Grid item md="auto">
            <Box className={classes.cancelLink}>
              <TextLink
                onClick={(e: any) => {
                  e.preventDefault();
                  return navigate(
                    backButtonRoute ? backButtonRoute : ROUTES.ACCOUNT,
                  );
                }}
              >
                {backButtonText}
              </TextLink>
            </Box>
          </Grid>
          <Grid item md="auto">
            <Box>
              {!Boolean(oneTimePayConfig.selectedAccordian) && (
                <PGEButton
                  type={'submit'}
                  variant="contained"
                  color="primary"
                  data-testid="change-password-button"
                  disabled={nextButton || disableNextButton}
                  size="large"
                  css={{
                    borderRadius: '40px',
                  }}
                >
                  {nextButtonText}
                </PGEButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default PaymentInfoForm;
