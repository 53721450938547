/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import { Box, Typography } from '@material-ui/core';
import colors from '../../themes/main-colors';
import { Link } from 'gatsby';
import { useTheme } from '@material-ui/core/styles';

export type SummarySectionProps = {
  sectionTitle: string;
  editLink: string;
  editLabel?: string;
  items: {
    label: string | undefined;
    values: (string | undefined)[];
  }[];
};

export const SummarySection: React.FC<SummarySectionProps> = ({
  sectionTitle = '',
  items = [],
  editLink,
  editLabel,
}) => {
  const theme = useTheme();
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant={'h4'}
          css={{
            wordWrap: 'break-word',
            width: '85%',
            fontSize: 20,
            fontWeight: 'bold',
            color: colors.midGrey,
            marginBottom: 1,
            [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
              fontSize: 16,
            },
          }}
        >
          {sectionTitle}
        </Typography>
        <Link
          to={editLink}
          css={{
            fontSize: '16px',
            fontWeight: 'bold',
            color: colors.sparkBlue,
            textDecoration: 'none',
            [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
              fontSize: 16,
            },
          }}
        >
          {editLabel}
        </Link>
      </Box>

      {items.map((item, idx) => (
        <Box key={item.label} css={{ padding: '0px 18px 0 18px' }}>
          <Typography
            variant={'body1'}
            css={{
              fontSize: 20,
              fontWeight: 'bold',
              color: colors.noirBlur,
              marginTop: '16px',
              lineHeight: '26px',
              [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                fontSize: 16,
              },
            }}
          >
            {item.label}
          </Typography>
          {item.values.map(val => (
            <Typography
              variant="body1"
              css={{
                wordWrap: 'break-word',
                fontSize: 20,
                color: colors.noirBlur,
                marginTop: 10,
                '&:first-child': {
                  marginTop: 1,
                },
                [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                  fontSize: 16,
                },
              }}
            >
              {val}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default SummarySection;
